import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import mixin from '@/utils/mixin'
import 'mint-ui/lib/style.css'
import { Actionsheet, DatetimePicker, Toast, Popup } from 'mint-ui'

Vue.component(Actionsheet.name, Actionsheet);
Vue.component(DatetimePicker.name, DatetimePicker);
Vue.component(Toast.name, Toast);
Vue.component(Popup.name, Popup);

Vue.prototype.$toast = Toast

import i18n from '@/assets/lang/index'

import ElementUI from 'element-ui'
import './assets/style/element-variables.scss'

import vConsole from 'vconsole'
if(process.env.NODE_ENV === 'dev') {
  new vConsole()
}

const requireComponent = require.context('./components/template', true, /[a-z]\w+\.(vue|js)$/)
// eslint-disable-next-line camelcase
let registerTemplateName_SAVE: Array<any> = []
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)
  const componentName = 'C' + componentConfig.default.uuid.replace(/-/g, '')
  registerTemplateName_SAVE.push(componentName)
  // 全局注册组件
  Vue.component(
    componentName,
    // 如果这个组件选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    componentConfig.default || componentConfig
  )
})

Vue.config.productionTip = false
Vue.mixin(mixin({registerTemplateName_SAVE}))
Vue.config.productionTip = false

Vue.use(ElementUI, {
  i18n: (key: any, value: any) => i18n.t(key, value)
})

Vue.prototype.openLoading = function(msg:any) {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,                             // 是否锁屏
    text: msg || '加载中...',                         // 加载动画的文字
    spinner: 'el-icon-loading',             // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.7)',       // 背景颜色
    target: '.v-application',       // **需要遮罩的区域，这里写要添加loading的选择器**
    fullscreen: false,
    customClass: 'loadingclass'             // **遮罩层新增类名，如果需要修改loading的样式**
  })
  return loading;
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
