import i18n from "@/assets/lang";
/*
 * order status list data
 */

export const BizType: string = "supplychain";

export const langDB = {
  en: "en",
  cn: "cn"
};

export const shipGroupNameDcuKeys = [
  "base.receive_gourp_name",
  "base.sender_gourp_name"
];

export const shipOrgNameDcuKeys = [
  "base.receive_org_name",
  "base.sender_org_name"
];

// dcp 场景
export enum Scene {
  SKU = "SKU",
  PRODUCT_MANAGEMENT = "PRODUCT_MANAGEMENT",
  LOGISTIS_SEND = "LOGISTIS_SEND",
  LOGISTIS_RECEIVE = "LOGISTIS_RECEIVE",
  BRAND_INFO = "BRAND_INFO"
}

export const dcpRoutes = {
  SKU: {
    route: "skuManage",
    title: "",
    scene: Scene.SKU
  },
  PRODUCT_MANAGEMENT: {
    route: "productManage",
    title: "",
    scene: Scene.PRODUCT_MANAGEMENT
  },
  LOGISTIS_SEND: {
    route: "deliveryManagement",
    title: "",
    scene: Scene.LOGISTIS_SEND
  },
  LOGISTIS_RECEIVE: {
    route: "receiveManagement",
    title: "",
    scene: Scene.LOGISTIS_RECEIVE
  },
  any: {
    route: "dcpManage",
    scene: "COMMON"
  }
};

export enum SubmitType {
  DRAFT = "DRAFT",
  SUBMIT = "SUBMIT"
}
