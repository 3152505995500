import axios from "axios";
const R = require('ramda');
import callApp from "@/services/sdk";
import { Loading } from 'element-ui';
import { ElLoadingComponent } from 'element-ui/types/loading'
import { parseQueryStr } from '@/utils/common'

let loading: ElLoadingComponent // 定义loading变量

function startLoading() {
  // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: 'Loading...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {
  // 使用Element loading-close 方法
  loading && loading.close()
}

const { language } = parseQueryStr(window.location.search);
const lang = !language ? 'zh' : language;  // 默认语言为中文

const network = axios.create({
  baseURL: window.Config.base_url,
  timeout: 6000,
  headers: {
    'Content-Type': 'application/json',
    'accept-language': lang,
  },
});

// 请求前拦截 是否要进行 token 数据的判断
network.interceptors.request.use((config: any) => {
  startLoading();
  let { query } = config.data;
  if (query.token) {
    config.headers.Authorization = `Bearer ${query.token}`;
  }
  delete config.data.query; // 删除 POST 请求时带上的 query 参数
  return config;
}, (error: any) => {
  return Promise.reject(error);
});

// 请求完成后对返回结果处理
network.interceptors.response.use((res: any) => {
  endLoading();
  res.data = !R.isEmpty(res.data) ? res.data : {};
  return res.data;
}, (error: any) => {
  endLoading();

  return Promise.reject(error);
})

export default network;
