import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Shanghai');

export const format = (text: string, type: string) => dayjs.utc(text).local().format(type);

export const toUTC = (text: string) => dayjs.utc(text).local().format();
