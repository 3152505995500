const u = navigator.userAgent
const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

const createRandom = () => {
	return +new Date() + Math.random().toString().substr(2, 4)
}

const callbackPromise = (callbackId: string, requestId: string, callBack: any | undefined = ()=>{}) => {
	return new Promise((resolve, reject) => {
		const windowObj = window as any
		windowObj[callbackId + requestId] = undefined
		windowObj[callbackId + requestId] = (jsonStr: any) => {
			try {
				var json = jsonStr
				if (json.code == 1) {
					if (callBack) callBack(json.data)
					resolve(json.data)
				} else {
					reject(json.message)
				}
			} catch(err) {
				alert('data format error')
			}
		}
	})
}

const postToApp = (method: string, params: any, cbId: string, requestId: string) => {
	var requestParameters = {
		type: params.type,
		callBackId: cbId,
		requestId: requestId,
		method: method,
		data: params.data,
	}

	if (isiOS) {
		setTimeout(() => {
			const windowObj = window as any
			windowObj.webkit.messageHandlers.vechain_nativeBridge.postMessage(requestParameters)
		})
	} else {
		const windowObj = window as any
		if (windowObj.vechain_nativeBridge) {
			setTimeout(() => {
				windowObj.vechain_nativeBridge.request(JSON.stringify(requestParameters))
			})
		} else {
			console.log("no_mobile_device_found")
		}
	}
}

(window as any)['vechain_nativeBridgeResponse'] = (responseObject: any) => {
	let response: any
	if (isiOS) {
		response = responseObject
	} else {
		response = JSON.parse(responseObject)
	}
	let requestId: string = response.requestId
	let callbackId: string = response.callBackId
	let callbackFunc: any = (window as any)[callbackId + requestId]
	callbackFunc(response)
}

(window as any)['vechain_nativeBridgeRequest'] = (data: any) => {
	if (!isiOS) {
		data = JSON.parse(data)
	}
	if (data.type === 'web.setVidSource') {
		(window as any)[data.type]()
	}
	if (data.type === 'web.getGps') {
		(window as any)[data.type]()
	}
	if (data.type === 'web.setVidData') {
		(window as any)[data.type + data.requestId](data.data)
	}
	if (data.type === 'web.setTextData') {
		(window as any)[data.type + data.requestId](data.data)
	}
	if (data.type === 'web.closeScanDialog') {
		(window as any)[data.type]()
	}
}

const callApp = (method: string, params: any, callBackId: string, callBack: any | undefined = ()=>{}, requestId: string | undefined) => {
	let reqId: string = requestId || createRandom()
	postToApp(method, params, callBackId, reqId)
	return callbackPromise(callBackId, reqId, callBack)
}

export default callApp
