import axios from "axios";
const R = require('ramda');
import { Message, Loading } from 'element-ui';
import { ElLoadingComponent } from 'element-ui/types/loading'
import { parseQueryStr } from '@/utils/common'

let loading: ElLoadingComponent // 定义loading变量

function startLoading() {
  // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: 'Loading...',
    background: 'rgba(0, 0, 0)'
  })
}

function endLoading() {
  // 使用Element loading-close 方法
  loading && loading.close()
}

const { language } = parseQueryStr(window.location.search);
const lang = !language ? 'zh' : language;  // 默认语言为中文

const bless_network = axios.create({
  baseURL: window.Config.bless_service_url,
  timeout: 6000,
  headers: {
    'Content-Type': 'application/json',
    'accept-language': lang,
  },
});

// 请求前拦截 是否要进行 token 数据的判断
bless_network.interceptors.request.use((config: any) => {
  // startLoading();
  // let { query } = config.data;
  // if (query.token) {
  //   config.headers.Authorization = `Bearer ${query.token}`;
  // }
  return config;
}, (error: any) => {
  return Promise.reject(error);
});

// 请求完成后对返回结果处理
bless_network.interceptors.response.use((res: any) => {
  // endLoading();
  // res.data = !R.isEmpty(res.data) ? res.data : {};
  return res;
}, (error: any) => {
  // endLoading();

  return Promise.reject(error);
})

export default bless_network;
