declare const global: any

// 解析jwt
export const parseJwt = (token: any) => {
  const base64Url = String(token).split('.')[1];
  const base64 = String(base64Url).replace('-', '+').replace('_', '/');
  // const atob = (str: any) => global.atob ? global.atob(base64) : Buffer.from(str, 'base64').toString('binary');
  const atob = (str: any) => global.atob && global.atob(base64);
  try {
    return JSON.parse(atob(base64));
  } catch (e) {
    return {};
  }
}
