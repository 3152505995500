import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		formRules: {},
		childModel: JSON.stringify([]),
		formSet: [],
		domain: '', // domain & token 是用来访问媒体资源的
		token: '',
		languagePackage: [],
		vidlistComponents: [], // 缓存vidWidget组件
		scannerTextComponents: [], // 缓存ScannerText组件
		currentScanType: '', // 当前扫描种类
		batchList: [], // sku绑定时，sku list和batch list 存在及联关系
		formStatus: 'init', // 页面状态 init， draft， submitedit
		softDataStatus: 'none', // 值为'disable'，表示数据正在上链，所有组件以及save、submit按钮不可编辑
		dataPointType: '', // 当前datamodel的种类，针对固有业务
		bigVid: '', // 集合绑定or解绑时，只有获取大码后才能操作小码，操作小码时要将已选择的大码(单扫)传给 app做校验
		uuid: '',
		childPageContext: {},
		viewDcp: {},
		indexScroll: 0,
		cacheData: {},
		datamodelUuid: '',
		preEditDataModelUuid: '',
		preEditStatus: true,
		dataInfo: undefined,
		loginToken: '',
		disabledKeyPathList: [], 	/// 不可编辑keypath
		extendList: [],				/// 头尾码等随数据额外存储内容.
		storedExtendList: [],		/// 已提交数据额外存储内容.

    dcpDataInfo: {}, // 动态表单提交的数据
    receiveInfo: {}, // 收货详情
    batchCodeWatch: '', // 监听批次信息变化，值发生变化时 enter 将请求model具体数据并渲染
    pageName: '', // 页面名称，同一个组件在不同页面中使用了，需要判断对应页面来进行区分
    selectReceive: {}, // 发货管理 - 收货方暂存对象，在提交 dcp 时需要用到

    deliverDcpData: {}, // 发货管理 - 动态表单结构数据

		wechat:{
			dcpTemplate: "",
			dcpLanguage: "",
			dcpData: "",
			bizDataType: "DATAPOINT",
			projectId: "",
			instanceUuid: "",
			recordData: "",
			datamodelUuid: "",
			bizLanguage: "zh-CN",
			version: ""
		},

	},
	mutations: {
		setFormRules: (state, data) => {
			state.formRules = data
		},
		setChildModel: (state, data) => {
			state.childModel = data
		},
		setFormSet: (state, data) => {
			state.formSet = data
		},
		setCurrentScanType: (state, data) => {
			state.currentScanType = data
		},
		setBatchList: (state, data) => {
			state.batchList = data
		},
		setBigVid: (state, data) => {
			state.bigVid = data
		},
		setFormStatus: (state, data) => {
			state.formStatus = data
		},
		setSoftDataStatus: (state, data) => {
			state.softDataStatus = data
		},
		setDataPointType: (state, data) => {
			state.dataPointType = data
		},
		setUuid: (state, data) => {
			state.uuid = data
		},
		setChildPageContext: (state, data) => {
			state.childPageContext = data
		},
		setViewDcp: (state, data) => {
			state.viewDcp = data
		},
		setDataInfo: (state, data) => {
			state.dataInfo = data
		},
		setDisabledKeyPathList: (state, data) => {
			state.disabledKeyPathList = data
		},
		setStoredExtendList: (state, data) => {
			state.storedExtendList = data
		},
    setDcpDataInfo: (state, data) => {
      state.dcpDataInfo = data
    },
    setReceiveInfo: (state, data) => {
      state.receiveInfo = data
    },
    setBatchCodeWatch: (state, data) => {
      state.batchCodeWatch = data
    },
    setPageName: (state, data) => {
      state.pageName = data
    },
    setSelectReceive: (state, data) => {
      state.selectReceive = data
    },
    setDeliverDcpData: (state, data) => {
      state.deliverDcpData = data
    },
	},
	getters: {
		getFormRules: (state) => {
			return state.formRules
		},
		getChildModel: (state) => {
			return state.childModel
		},
		getFormSet: (state) => {
			return state.formSet
		},
		getCurrentScanType: (state) => {
			return state.currentScanType
		},
		getBatchList: (state) => {
			return state.batchList
		},
		getBigVid: (state) => {
			return state.bigVid
		},
		getFormStatus: (state) => {
			return state.formStatus
		},
		getSoftDataStatus: (state) => {
			return state.softDataStatus
		},
		getDataPointType: (state) => {
			return state.dataPointType
		},
		getChildPageContext: (state) => {
			return state.childPageContext
		},
		getViewDcp: (state) => {
			return state.viewDcp
		},
		getDataInfo: (state) => {
			return state.dataInfo
		},
		getDisabledKeyPathList: (state, data) => {
			return state.disabledKeyPathList
		},
		getStoredExtendList: (state, data) => {
			return state.storedExtendList
		},
    getDcpDataInfo: (state) => {
      return state.dcpDataInfo
    },
    getReceiveInfo: (state) => {
      return state.receiveInfo
    },
    getBatchCodeWatch: (state) => {
      return state.batchCodeWatch
    },
    getPageName: (state) => {
      return state.pageName
    },
    getSelectReceive: (state) => {
      return state.selectReceive
    },
    getDeliverDcpData: (state) => {
      return state.deliverDcpData
    },
	},
	actions: {
		setFormRules: ({commit}, data) => {
			commit('setFormRules', data)
		},
		setChildModel: ({commit}, data) => {
			commit('setChildModel', data)
		},
		setFormSet: ({commit}, data) => {
			commit('setFormSet', data)
		},
		setCurrentScanType: ({commit}, data) => {
			commit('setCurrentScanType', data)
		},
		setBatchList: ({commit}, data) => {
			commit('setBatchList', data)
		},
		setBigVid: ({commit}, data) => {
			commit('setBigVid', data)
		},
		setFormStatus: ({commit}, data) => {
			commit('setFormStatus', data)
		},
		setSoftDataStatus: ({commit}, data) => {
			commit('setSoftDataStatus', data)
		},
		setDataPointType: ({commit}, data) => {
			commit('setDataPointType', data)
		},
		setChildPageContext: ({commit}, data) => {
			commit('setChildPageContext', data)
		},
		setViewDcp: ({commit}, data) => {
			commit('setViewDcp', data)
		},
		setDataInfo: ({commit}, data) => {
			commit('setDataInfo', data)
		},
		setDisabledKeyPathList: ({commit}, data) => {
			commit('setDisabledKeyPathList', data)
		},
		setStoredExtendList: ({commit}, data) => {
			commit('setStoredExtendList', data)
		},
    setDcpDataInfo: ({commit}, data) => {
      commit('setDcpDataInfo', data)
    },
    setReceiveInfo: ({commit}, data) => {
      commit('setReceiveInfo', data)
    },
    setBatchCodeWatch: ({commit}, data) => {
      commit('setBatchCodeWatch', data)
    },
    setPageName: ({commit}, data) => {
      commit('setPageName', data)
    },
    setSelectReceive: ({commit}, data) => {
      commit('setSelectReceive', data)
    },
    setDeliverDcpData: ({commit}, data) => {
      commit('setDeliverDcpData', data)
    },
	}
})
