const mapScript = {
  AMap: 'https://webapi.amap.com/maps?v=1.4.15&key=19e97b8f7c11df0365a2993400a2f468&plugin=AMap.Geocoder&callback=initAMap',
  GMap: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyASe6ft8ZxdIRHpveNxSpC1JvN7Uv9Sau4&callback=initGMap&lang=en-za'
}

import { noop, isFunction } from '@/utils/index'

export default (type, cb = noop) => {
  if (isFunction(type)) {
    cb = type
    type = 'AMap'
  }
  let veMap = {
    AMap: window.AMap,
    GMap: (window.google || {}).maps
  }[type]
  if (veMap && document.getElementById(type)) {
    cb(veMap)
  } else {
    var script = document.createElement('script')
    script.src = mapScript[type]
    script.id = type
    script.charset = 'utf-8'
    document.head.appendChild(script)
    window.initAMap = _ => {
      setTimeout(_ => {
        cb(window.AMap)
      }, 500)
    }
    window.initGMap = _ => {
      setTimeout(_ => {
        cb(window.google.maps)
      }, 500)
    }
  }
}
