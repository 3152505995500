const R = require('ramda');

// get 请求时参数的整合
export const createQueryStr = (qs: any) => {
  if (R.isEmpty(qs)) return '';
  const params = Object.keys(qs);
  let str = '';
  params.forEach((item) => {
    if (item && qs[item]) { // 如果value没有则key也不需要
      str += `${item}=${qs[item]}&`;
    }
  });
  str = str.substring(0, str.length - 1);
  return str
}

// 图片上传大小限制 9.5M
export const imageLimit = (size: number) => {
  const IMAGE_LIMIT = 9.5 * 1024 * 1024;
  if (size > IMAGE_LIMIT) {
    return false;
  }
  return true;
}

// 音频、视频上传大小限制 28.6M
export const videoLimit = (size: number) => {
  const VIDEO_LIMIT = 28.6 * 1024 * 1024;
  if (size > VIDEO_LIMIT) {
    return false;
  }
  return true;
}

export const sleep = (time: number) => {
  return new Promise((resolve: any) => setTimeout(resolve, time));
}

export const parseQueryStr = (search: string) => {
  if (!search) return {};
  // 取得没有开头问号的查询字符串
  let qs = (search.length > 0 ? search.substring(1) : "");
  // 保存数据的对象
  const args: any = {};
  // 把每个参数添加到 args 对象
  for (let item of qs.split("&").map(kv => kv.split("="))) {
    let name = decodeURIComponent(item[0]), value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args;
}
