import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/dcpdetail/enter.vue')
  },
  {
    path: '/dcplist',
    name: 'dcplist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dcplist" */ '../views/dcplist.vue')
  },
  {
    path: '/remark',
    name: 'remark',
    component: () => import(/* webpackChunkName: "remark" */ '../views/remark.vue')
  },
  {
    path: '/bless',
    name: 'bless',
    component: () => import(/* webpackChunkName: "bless" */ '../views/bless.vue')
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import(/* webpackChunkName: "survey" */ '../views/survey.vue')
  },
  {
    path: '/cert',
    name: 'cert',
    component: () => import(/* webpackChunkName: "cert" */ '../views/cert.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
