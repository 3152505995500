// store/modules/passenger.ts
import {Module,VuexModule,Mutation,Action,getModule,} from 'vuex-module-decorators';
import store from '@/store';

// dynamic: true: 动态创建动态模块,即new Vuex.Store({})里面不用注册的.空着就行,
// store,当前模块注册到store上.也可以写在getModule上,即getModule(PassengerStore,store)
// namespaced: true, name: 'passenger' 命名空间
@Module({
  name: 'common', dynamic: true, namespaced: true, store,
})
export default class commonStore extends VuexModule {
  // state => 要public不然外面调用不到
  public langLocale:string = 'langLocale'
  public sysEnvironment:string = 'sysEnvironment'
  public applyNo:string = 'applyNo'
  public userInfo: any = {};
  public token: string = '';
  public x_api_token:string ='x-api-token';
  public x_bu_user:string = 'x-bu-user';
  public orgList: string = 'orgList';
  public roleInfo: string = 'roleInfo';

  //收货时的customId
  public customIdOnReceive: string = '';
  get getCustomIdOnReceive(): string {
    return this.customIdOnReceive;
  }

  @Mutation
  public changeCustomIdOnReceive(data: string) {
    this.customIdOnReceive = data
  }

  // 角色信息
  public _roleInfo: object = {};
  public get getRoleInfo(): any {
    return this._roleInfo;
  }
  @Mutation
  public changeRoleInfo(data: object) {
    this._roleInfo = data
  }

  /// totalcumbs
  public breadcrumbs: Array<Object> = [];

  /// nav namespace for left / top menu
  public breadnav: Array<Object> = [];

  // getters
  get userNumber(): number {
    return this.userInfo.length;
  }

  get breadcrumbsList(): Array<Object> {
    return this.breadcrumbs;
  }

  get breadnavList(): Array<Object> {
    return this.breadnav;
  }

  get userInfoContent(): Object {
    return this.userInfo;
  }

  @Mutation
  USERINFO(user: any): void {
    this.userInfo = user;
  }
  @Mutation
  BREADCRUMBS(bread: Array<Object>): void {
    this.breadcrumbs = bread
  }
  @Mutation
  BREADNAV(namespace: Array<Object>): void {
    this.breadnav = namespace
  }

  @Mutation
  CHANGETOKEN(token: string): void {
    this.token = token
  }

  @Mutation
  changeBreadcrumbs(bread: Array<Object>) {
    this.breadcrumbs = bread
  }

  // commit的两种调用方式,第一种,Action后面的括号里面添加commit,然后return的结果就是USERINFO的参数
  @Action({ commit: 'USERINFO' })
  getZhangsan(): any {
    return { username: '张三', password: 'zhangsan' };
  }
  // 第二种,直接this.USERINFO调用;
  @Action
  getLisi(): void {
    const user = { username: '李四', password: 'lisi' };
    this.context.commit('USERINFO', user); // commit调用
    // this.USERINFO(user); // 直接调用
  }
}
// 使用getModule: 对类型安全的访问
export const commonStoreModule = getModule(commonStore);
