import { Base64 } from 'js-base64'
export const isMobile = (() => {
  let UA = navigator.userAgent
  let Sys = {
    ios: !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: UA.indexOf('Android') > -1 || UA.indexOf('Adr') > -1 // android终端
  }
  return Sys.ios || Sys.android
})()

export const types = (val: any) => {
  return typeof val
}

export const isObject = (val: any) => {
  return types(val) === 'object'
}

// 获取语言
export const slang = (val: any) => {
  let ln = val || storage('sysLanguage').get()
  return ln || 'zh'
}

// 是否布尔值
export const isBoolean = (val: any) => {
  return types(val) === 'boolean'
}

// 判断是否为数组
export const isString = (val: any) => {
  return types(val) === 'string'
}

// 判断是否为方法
export const isFunction = (val: any) => {
  return types(val) === 'function'
}

// 判断是否数字
export const isNumber = (val: any) => {
  return types(val) === 'number'
}

// 判断是否为数组
export const isObjectString = (val: any) => {
  return isString(val) && /^(\{.*\}|\[.*\])$/.test(val)
}

// 强制为数字类型
export const toNumber = (num: any) => {
  return parseFloat(num)
}

/**
 *判断是否是数字
 **/
export const isRealNum = (val: any) => {
  // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
  if (val === '' || val == null) {
    return false
  }
  return !isNaN(val)
}

//存local
export const storage = (name: string): any => {
  let __store = window.localStorage
  let store: any = __store.getItem(name)
  try {
    store = store ? Base64.decode(store) : store
  } catch (err) {
    store = ''
  }

  return {
    set: (data: any) => {
      data = Base64.encodeURI(isObject(data) ? JSON.stringify(data) : data)
      __store.setItem(name, data)
    },
    get: () => (isObjectString(store) ? JSON.parse(store) : store),
    remove: () => __store.removeItem(name)
  }
}

let sysConfig: any = {
  lang: 'default'
}

export const setCookie = (name: string, value: any, day: number) => {//存cookie
  if (!name) return
  let Days = day || 1 //默认1天
  var exp = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  value = Base64.encodeURI(isObject(value) ? JSON.stringify(value) : value)
  document.cookie = name + '=' + encodeURIComponent(value) + ';expires=' + exp.toUTCString()
}

export const getCookie = (name: string) => {//取cookie
  var arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'))
  if (arr != null) return decodeURIComponent(Base64.decode(arr[2]))
  return null
}

export const getDotCookie = (name: string) => {//取cookie
  var arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'))
  if (arr != null) return decodeURIComponent(arr[2])
  return null
}

export const delCookie = (name: string) => {//删cookie
  setCookie(name, "", -1);
}

// 输入框非空验证
export const checkNull = (msg: any) => {
  return (rule: any, value: string, callback: (arg0: Error | undefined) => void) => {
    const trimValue = value.replace(/^\s+|\s+$/gm, '')
    if (value === '' || trimValue === '') {
      return callback(new Error(msg))
    } else {
      callback(undefined)
    }
  }
}

export const noop = (data: any) => {}

//  格式化时间
export const dateFormat = (dateNumber: number, format = 'yyyy-MM-dd HH:mm:ss') => {
  let date = new Date(dateNumber);
  let y: string = String(date.getFullYear());
  let M: string = String(date.getMonth() + 1);
  let d: string = String(date.getDate());
  let h: string = String(date.getHours());
  let m: string = String(date.getMinutes());
  let s: string = String(date.getSeconds());
  format = format.replace('yyyy', y)
  format = format.replace('MM', coverData(M))
  format = format.replace('M', M)
  format = format.replace('dd', coverData(d))
  format = format.replace('d', d)
  format = format.replace('HH', coverData(h))
  format = format.replace('H', h)
  format = format.replace('mm', coverData(m))
  format = format.replace('m', m)
  format = format.replace('ss', coverData(s))
  format = format.replace('s', s)
  return format
}

export const coverData = (obj = '', n = 2) => {
  obj = obj.toString()
  if (obj.length >= n) {
    return obj
  }
  obj = '0000000000' + obj
  return obj.substr(obj.length - n)
}

export const FormatQuery = (query: Object) => {
  let obj = JSON.parse(JSON.stringify(query))
  let keys = Object.keys(obj) || []
  let result = ""
  keys.forEach(item => {
    if(result.length > 0){
      result += "&"
    }
    result += item
    result += "="
    result += obj[item]
  })
  return result
}

export const queryObject = ()=>{
  let querys = window.location.search.replace('?',"").split("&")
  let queryMap: any = {}
  querys.forEach(item => {
    let kvList = item.split("=")
    let key = kvList[0]
    queryMap[key] = kvList[1]
  })
  return queryMap
}

export const workApp = (()=>{
  let UA = navigator.userAgent
  let Sys = {
    vechainwork: UA.indexOf('VeChainWork') > -1 || UA.indexOf('vechainwork') > -1 // android终端
  }
  return Sys.vechainwork
})()

export const DcpTypeMap = {
  // 200002: bind, 200005: track, 200003: collectionBind, 200004: collectionUnBind, custom, 200015: dcphash
        // 200018: productManage
  "200002": "bind",
  "200005": "track",
  "200003": "collectionBind",
  "200004": "collectionUnBind",
  "200015": "dcphash",
  '200018': 'productManage'
}

export default {
  sysConfig
}
