<template>
  <!-- 为批次信息使用, model 返回的 uuid 相同, 渲染了相同的组件，因此要使用 key来做区分  key=bigvid -->
  <div class="pc-vidlist-component components-relative" v-show="visible">
    <el-button icon="el-icon-circle-plus" type="text" class="icon-add-type-button" circle @click="initCreate()" :disabled="disabledEdit" />

    <el-button class="icon-delete-all-button" icon="el-icon-delete" type="text" @click="deleteAllVid()" v-show="UseVIDList.length > 0" :disabled="disabledEdit" />

    <el-form class="pc-vidlist-component-form" style="margin: 0" label-position="top" @submit.native.prevent :rules="rules" ref="childForm" :model="formLabelAlign">
      <el-form-item prop="value">
        <span slot="label" class="duc-label">
          {{ localized(`label`) }}
          <span v-if="fatherContent.displayReference.description !== ''">
            <el-popover ref="popover1" :width="400" trigger="hover" :content="fatherContent.displayReference.description" />
            <span class="file-upload-info_span" v-popover:popover1>
              <i class="el-icon-question"></i>
            </span>
          </span>
        </span>

        <!-- Vid List -->
        <div class="infinite-list" style="overflow: auto; padding-left: 10px; width: 100%; overflow-x: hidden;">
          <tableList :tableInfo="getTableList" :operationNumber="0.3" :emptyText="$t('datapoint.empty_id_list_hint')" :tableModel="tableModel">
            <template slot-scope="slotData">
              <div class="setting-button-container">
                <el-button class="main-setting-button-radius" type="plain" size="small" :disabled="disabledEdit"
                  @click="handleDeleteClick(slotData.slotData.$index)">{{ $t("common.button_setting_remove") }}</el-button>
              </div>
            </template>
          </tableList>
        </div>
      </el-form-item>
    </el-form>

    <div class="components-vidlist-buttom"> {{ UseVIDList.length }}/{{ fatherContent.controlReference.max }}</div>

    <!-- Dialog for add Vid-->
    <el-dialog :customClass="vidListDialog" width="90%" :append-to-body="true" :visible.sync="dialogVisible" :title="$t('datapoint.add_id_bigvid_title')">
      <div>
        <!-- 录入方式 -->
        <el-row :gutter="16">
          <el-col :span="24">
            <el-form :model="ruleForm" label-position="top">
              <el-form-item :label="$t('datapoint.add_id_dialog_type')" prop="region">
                <el-select size="small" v-model="mode" style="width: 100%">
                  <el-option v-for="(item, index) in addIdType" :value="item.value" :label="item.label" :key="index" />
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>

        <!--选订单-->
        <el-row :gutter="16" v-if="mode === 'Order'">
          <el-col :span="24">
            <el-form ref="ruleForm" label-position="top">
              <el-form-item :label="$t('common.components_title_selectorder')" prop="region">
                <el-select popper-class="skuListSelect" :loading="loading" v-model="ruleForm.region" size="small" style="width: 100%" placeholder>
                  <template style="position: relative">
                    <div class="options-box">
                      <el-option v-for="item in currentOrderList" :value="item.order_no" :label="item.name" :key="item.order_no" style="height: 90px">
                        <div class="order-item-vidlist" @click="selectOrderAvailableNumber = item.usable_number">
                          <el-image :src="item.goods_image" style="width: 80px; height: 80px" />
                          <div class="order-item-infomation">
                            <div style="display: flex; align-items: flex-end">
                              {{ item.name }}
                              <span>
                                <span style="margin-right: 15px">{{ $t("common.table_title_availiable") }} {{ item.usable_number }}</span>
                                <span style="margin-right: 15px">{{ $t("common.table_title_total") }} {{ item.goods_quantity }}</span>
                              </span>
                            </div>
                            <div>
                              {{ item.subOrderName }}
                              <span style="color: #999999">{{ getDateFormat(item.modified) }}</span>
                            </div>
                          </div>
                        </div>
                      </el-option>
                    </div>
                  </template>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>

        <!-- Added by input-->
        <el-row :gutter="16" v-if="mode === 'Input'">
          <el-col :span="24">
            <el-form @submit.native.prevent :model="ruleForm" :rules="newRules" ref="ruleForm" label-position="top">
              <el-form-item :label="$t('datapoint.custom_id')" prop="name">
                <ul class="infinite-list" style="overflow: auto; margin-bottom: 20px">
                  <li v-for="(i, index) in ruleForm.inputVidList"
                    :key="i"
                    class="infinite-list-item"
                    :style="errorItem.includes(index) ? 'background:#F56C6C' : ''"
                  >
                    <span>{{ i }}</span>
                    <i style="cursor: pointer" class="el-icon-remove-outline" @click="removeVid(i)"></i>
                  </li>
                </ul>
              </el-form-item>
              <el-form-item>
                <div style="display: flex">
                  <el-input
                    :disabled="maxInput === ruleForm.inputVidList.length"
                    @keyup.enter.native="setInputSider" maxlength="66"
                    :placeholder="$t('common.components_message_add')"
                    v-model.trim="inputEnterSider"
                  />
                  <el-button style="margin-left: 16px" :disabled="maxInput === ruleForm.inputVidList.length || typeError"
                    type="primary" size="small" @click="setInputSider" plain>{{ $t("common.operator_add") }}</el-button>
                </div>
              </el-form-item>
            </el-form>
            <div>{{ ruleForm.inputVidList.length }}/{{ maxInput }}</div>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="footer-class">
        <v-btn color="#2196f3" :dark="!submitDisable" :disabled="submitDisable" :loading="loading"
        elevation="0" min-height="42px" min-width="140px" style="margin-right: 25px" @click="saveBigVidList()">
          {{ $t("common.button_reset_comfirm") }}
        </v-btn>
        <v-btn outlined :loading="loading" color="primary" style="color: #2196f3" min-height="42px" min-width="140px" @click="dialogVisible = false">
          {{ $t("common.common_button_cancel") }}
        </v-btn>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import language from "@/utils/lang";
import VeTrueApi from '@/api/vetrue';
import { format } from "@/utils/time";

import VidResultList from '@/components/common/vidResultList.vue';
import tableList from "@/components/common/tempTableList.vue";

export default {
  name: "vid-for-web",
  props: [
    "jsonContent",
    "fatherContent",
    "datamodel",
    "disabled",
    "host",
    "listHost",
    "contextData",
  ],
  inject: {
    commonSave: {
      default: {}
    }
  },
  components: {
    VidResultList,
    tableList,
  },
  data() {
    return {
      flag: 0,
      currentVid: "",
      orderVidData: [],
      rfidData: [],
      failRfidData: [],
      formLabelAlign: {
        value: ""
      },
      vidType: 0,
      showContent: "",
      idType: this.$t("app.id_type_custom"),
      vidSourceType: "vid",

      dialogVisible: false, // 弹窗控制
      loading: false,
      type: 1, // 0: vid;  1: custom id
      UseVIDList: [], // {vid: xx, code: 0}
      mode: 'Order',
      typeError: true,
      row: 0,
      OrderListArray: [],
      selectOrderAvailableNumber: 0,
      errorItem: [],
      inputEnterSider: "",
      ruleForm: {
        region: "",
        total: 0,
        number: 0,
        inputVidList: [],
        beginId: "",
        endId: "",
        logistaicsCode: "",
      },

      newRules: {
        region: [{
          required: true,
          message: " ",
          trigger: "change",
        }, ],
        number: [{
          required: true,
          message: " ",
          trigger: "blur",
        }, ],
        name: [{
          type: "array",
          required: true,
          message: " ",
          trigger: "change",
        }, ],
        beginId: [{
          required: true,
          message: " ",
          trigger: "blur",
        }, ],
        endId: [{
          required: true,
          message: " ",
          trigger: "blur",
        }, ],
      },
    };
  },
  computed: {
    ...mapGetters({
      scanType: "getCurrentScanType",
      formStatus: "getFormStatus",
      bigVid: "getBigVid",
      dataPointType: "getDataPointType",
      dataInfo: "getDataInfo",
      editStatus: "getFormStatus",
      storeDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    vidListDialog() {
      return "scan-setting"
    },
    disabledEdit() {
      const query = this.$route.query;
      // 赋码追溯 - 修改时 - 批次码/商品码（不能编辑）
      const isProductBind = query.dcp_data_type === 'PRODUCT_BIND' && query.biz_data_id;
      if (isProductBind) {
        return true;
      } else {
        return this.disabled; // 父级数据控制
      }
    },
    isCopy() {
      return this.$route.query && this.$route.query.copyRecord;
    },
    currentType() {
      return this.dataPointType === "collectionUnBind" ||
        this.dataPointType === "collectionBind" ||
        this.dataPointType === "productManage" ?
        ((this.fatherContent.key || "").toLowerCase() === "bigvid" ?
          "bigvid" :
          "subvid") :
        "";
    },
    item() {
      return this.fatherContent.controlReference;
    },
    item_name() {
      return this.fatherContent.key;
    },
    rules() {
      return {
        value: [{
          type: "array",
          required: this.fatherContent.controlReference.required,
          message: this.$t('app.required_error'),
          trigger: "blur"
        }]
      };
    },
    itemdata: {
      get() {
        if (this.datamodel && this.datamodel.vidlist && !this.isCopy) {
          this.rfidData = this.datamodel.vidlist.map(vid => {
            let vidCode = 1;
            return {
              vid: vid,
              code: vidCode
            };
          });

          if (this.item.max === 1) return this.datamodel.vidlist[0];
          return this.datamodel.vidlist && this.datamodel.vidlist.length ? this.datamodel.vidlist.length : "";
        } else {
          return "";
        }
      },
      set(val) {
        if (!val) {
          this.$nextTick(_ => {
            this.showContent = "";
          });
          this.formLabelAlign.value = [];
          return false;
        }
        let data = {
          vidlist: val ? this.rfidData.map(data => data.vid) : [],
          type: 0
        };
        this.formLabelAlign.value = data.vidlist;
        let vidListLength = (data.vidlist && data.vidlist.length) || 0;
        if (this.item.max === 1 || vidListLength <= 1) {
          let showVid = data.vidlist[0];
          let vid = showVid.replace(showVid.substring(6, showVid.length - 6), "......");
          if (data.vidlist[0].length <= 12) {
            vid = showVid;
          }
          this.$nextTick(_ => {
            this.showContent = vid;
          });
        } else {
          this.$nextTick(_ => {
            this.showContent = data.vidlist.length;
          });
        }
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key;
    },
    selectorVidData() {
      let datamodeVidList = (this.datamodel && !this.isCopy) ? this.datamodel.vidList : [];
      return (datamodeVidList || []).concat(this.orderVidData);
    },
    isCustomId() {
      return this.type === 1;
    },
    addIdType() {
      let typeArray = [];

      let orderType = {
        value: "Order",
        label: this.$t("datapoint.add_id_dialog_order_type"),
      };

      let inputType = {
        value: "Input",
        label: this.$t("datapoint.add_id_dialog_input_type"),
      };

      typeArray.push(orderType);
      typeArray.push(inputType);

      return typeArray;
    },
    submitDisable() {
      if (this.isLogistaicsCode && this.mode === "Order") {
        return !this.ruleForm.logistaicsCode;
      }
      return this.mode === "Order" ? !this.ruleForm.region : this.ruleForm.inputVidList.length === 0;
    },
    maxInput() {
      // return ~~this.fatherContent.controlReference.max - this.UseVIDList.length;
      // 批次信息自定义id每次都可以输入1个，替换前面内容
      return ~~this.fatherContent.controlReference.max;
    },
    getTableList() {
      let reCall = [];
      this.UseVIDList.forEach((element) => {
        let ids = element.vid.split(",");
        let item = {
          idType: this.type === 1 ? "Custom ID" : "VID",
          id: element.code == 1 && ids.length > 1 ? ids[1] : ids[0],
        };
        reCall.push(item);
      });
      return reCall;
    },
    tableModel() {
      return [
        { prop: "idType",width: 20 },
        { prop: "remark", width: 40 },
      ];
    },
    currentOrderList() {
      return this.OrderListArray;
    },
  },
  watch: {
    bigVid: function () {
      this.flag++;
      this.$nextTick(() => {
        if (this.formStatus !== "init" && this.flag > 1) {
          if (this.currentType === "subvid") {
            this.rfidData = [];
            this.failRfidData = [];
            this.$nextTick(() => {
              this.itemdata = "";
            });
          }
        }
      });
    },
    formLabelAlign: {
      handler(val) {
        var data = {
          vidlist: val.value,
          type: this.type,
          num: (val.value && val.value.length) || 0
        };
        if (val.value === undefined || val.value === "" || val.value.length === 0) {
          data = undefined;
        }
        this.$emit("confirm", data);  // 往vid父组件推数据
      },
      deep: true
    },
    inputEnterSider(val) {
      if (val.includes("=")) {
        this.inputEnterSider = val.split("=")[1];
      }

      if (this.isCustomId) {
        this.typeError = val.length === 0;
        return false;
      }

      switch (val.length) {
        case 40:
          this.typeError = !/^[\da-fA-F]{40}$/.test(val);
          break;
        case 42:
          this.typeError = !/^(0[xX])?[\da-fA-F]{40}$/.test(val);
          break;
        case 64:
          this.typeError = !/^[\da-fA-F]{64}$/.test(val);
          break;
        case 66:
          this.typeError = !/^(0[xX])?[\da-fA-F]{64}$/.test(val);
          break;
        default:
          this.typeError = true;
          break;
      }
    },
  },
  created() {
    console.log(this.fatherContent.key, 'this.fatherContent.key');
    this.dialogVisible = false;
    let contextValue = this.datamodel ? this.datamodel : undefined;
    if (contextValue) {
      if (contextValue.vidlist) {
        this.type = contextValue.type;
        this.UseVIDList = contextValue.vidlist.map((item) => {
          return {
            vid: item,
            code: this.type, // 用类型进行区分，在订单确认后填充数据时进行判断 getTableList 方法中需要这个来判断
          };
        });
      }
    }
  },
  mounted() {
    // HIGH 复制时依旧需要展示标签列表数据并进行 formLabelAlign 内容的填充，否则不修改直接提交时丢失数据
    // if (this.datamodel && !this.isCopy) {
    if (this.datamodel) {
      if (this.datamodel.type === 1) {
        this.idType = this.$t("app.id_type_custom");
        this.vidSourceType = "customid";
        // HIGH 芯片类型数据原先处理会丢失掉vid，导致数据更新时出现数据校验不正确
        // this.formLabelAlign.value = (this.datamodel.vidlist || []).map(item => {
        //   let idlist = item.split(",");
        //   return idlist[1] || idlist[0];
        // });
        this.formLabelAlign.value = this.datamodel.vidlist;
        console.log(this.formLabelAlign, 'mounted this.formLabelAlign');
      } else {
        this.idType = this.$t("app.id_type_vid");
        this.vidSourceType = "vid";
        this.formLabelAlign.value = this.datamodel.vidlist;
      }
    }

    if (this.datamodel && !this.isCopy && this.datamodel.type === 1) {
      this.datamodel.vidlist = this.datamodel.vidlist.map(item => {
        let idlist = item.split(",");
        return idlist[1] || idlist[0];
      });
    }

    if (this.item.max === 1) {
      let showVid = (this.datamodel && !this.isCopy) ? this.datamodel.vidlist[0] : "";
      let vid = showVid;
      if (showVid && showVid.length > 12) {
        vid = showVid.replace(showVid.substring(6, showVid.length - 6), "......");
      }

      this.$nextTick(_ => {
        this.showContent = vid;
      });
    } else {
      this.showContent = (this.datamodel && !this.isCopy && this.datamodel.vidlist) ? this.datamodel.vidlist.length : "";

      if (this.datamodel && !this.isCopy && this.datamodel.vidlist && this.datamodel.vidlist.length == 1) {
        let showVid = this.datamodel && this.datamodel.vidlist[0];
        let vid = showVid;
        if (showVid && showVid.length > 12) {
          vid = showVid.replace(showVid.substring(6, showVid.length - 6), "......");
        }

        this.$nextTick(_ => {
          this.showContent = vid;
        });
      }
    }
  },
  methods: {
    // 获取可用 VID List
    async getUsableTagList(params) {
      const vids = await VeTrueApi.getUsableTagList({
        query: this.$route.query,
        project_uuid: this.$route.query.project_uuid,
        label_type: 'BATCH_CODE', // 商品标签    PRODUCT_CODE      批次       BATCH_CODE
        ...params,
      });
      if (Object.keys(vids).length === 0) return [];
      // 取消二维码和芯片tab，则需要从后端获取到的数据 UseVIDList 中进行区分, custom_id 有值为1(二维码 Custom_ID), 否则为0(芯片 VID) (2022/12/2)
      this.type = vids[0].custom_id ? 1 : 0;
      return vids.map((item) => {
        return {
          vid: item.custom_id ? `${item.vid},${item.custom_id}`: item.vid, // 增加逻辑判断
          code: 1
        }
      });
    },
    // 可用订单列表 订单可用数量 取消芯片和二维码区分，因此只需要一个列表(2022/12/2)
    async getUsableOrderList() {
      // id_type 字段为非必填，初始化时应该获取全部
      const params = {
        project_uuid: this.$route.query.project_uuid,
        label_type: 'BATCH_CODE', // 订单列表接口 - 批次信息时传 BATCH_CODE
      }
      const orders = await VeTrueApi.getUsableOrderList({ query: this.$route.query, params });
      if (Array.isArray(orders)) {
        orders.sort((a, b) => b.id - a.id); // 数据进行排序，时间倒序
      }
      this.OrderListArray = orders.length > 0 ? [...orders] : [];
    },
    initCreate() {
      this.dialogVisible = true;
      this.getUsableOrderList();
    },
    cleanInputVidList() {
      this.ruleForm = {
        region: "",
        total: 0,
        number: 0,
        inputVidList: [],
        beginId: "",
        endId: "",
        logistaicsCode: "",
      };
      this.selectOrderAvailableNumber = 0;
      this.errorItem = [];
      this.tempSaveVidList = [];
      this.inputEnterSider = "";
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    },
    deleteAllVid() { // 清空列表 vid
      this.UseVIDList = [];
      this.formLabelAlign.value = this.UseVIDList;
      // TODO 不需要自动校验，而是提交时校验
      // this.$refs.formLabelAlign.validate();
    },
    handleDeleteClick(i) { // 删除指定 vid
      this.UseVIDList.splice(i, 1);
      this.formLabelAlign.value = this.UseVIDList;
    },
    setInputSider() {
      if (this.inputEnterSider.length === 0) {
        // 过滤空字符时用户点击回车键
        return false;
      }
      if (this.typeError) {
        this.$message.error(this.$t("common.components_message_invalid"));
        return false;
      }
      if (this.maxInput - this.ruleForm.inputVidList.length > 0) {
        if (this.isCustomId) {
          this.setCustomInputSider();
        } else {
          this.setVidInputSider();
        }
      }
    },
    setCustomInputSider() {
      if (this.ruleForm.inputVidList.includes(this.inputEnterSider)) {
        this.$message.error(this.$t("common.components_message_repeat"));
        this.inputEnterSider = "";
        return false;
      }
      this.ruleForm.inputVidList.push(this.inputEnterSider);
      this.inputEnterSider = "";
    },
    setVidInputSider() {
      if (this.ruleForm.inputVidList.includes(this.inputEnterSider.toLowerCase())) {
        this.$message.error(this.$t("common.components_message_repeat"));
        this.inputEnterSider = "";
        return false;
      }
      if (this.inputEnterSider.length === 40 || this.inputEnterSider.length === 64) {
        this.ruleForm.inputVidList.push("0x" + this.inputEnterSider.toLowerCase());
      } else {
        this.ruleForm.inputVidList.push(this.inputEnterSider.toLowerCase());
      }
      this.inputEnterSider = "";
    },
    getDateFormat(timestamp) {
      if (timestamp) {
        return format(timestamp, 'YYYY-MM-DD HH:mm:ss')
      } else {
        return "-";
      }
    },
    isIdInclude(vid) {
      return this.isCustomId ? this.isCustomIdInclude(vid) : this.isVIdInclude(vid);
    },
    isVIdInclude(vid) {
      return this.UseVIDList.filter((item) => item.vid.split(",")[0] === vid.toLowerCase()).length !== 0;
    },
    isCustomIdInclude(vid) {
      return this.UseVIDList.filter((item) => item.vid.split(",")[1] === vid).length !== 0;
    },
    removeVid(id) {
      this.ruleForm.inputVidList = this.ruleForm.inputVidList.filter(item => item !== id);
    },
    async saveBigVidList() {
      if (this.mode === "Order") {
        // 订单选择时传 order_no
        const params = { order_no: this.ruleForm.region };
        const list = await this.getUsableTagList(params);
        // 订单选择时根据使用数量从前开始获取数据，批次信息只有1个
        const newSelect = list.slice(0, 1);

        // 判断是否已经有选择过的
        let flag = false;
        this.UseVIDList.map((el) => {
          if (newSelect.find(item => item.vid === el.vid)) {
            this.$message.error(this.$t("datapoint.add_id_dialog_end_error_id"));
            flag = true;
          }
        })
        if (!flag) {
          // 每次修改都会替换原先数据
          this.UseVIDList = newSelect;
        }
      } else if (this.mode === "Input") {
        this.errorItem = [];
        let tempItemSaveList = [];
        this.ruleForm.inputVidList.forEach((item, index) => {
          if (this.isIdInclude(item)) {
            this.errorItem.push(index);
          } else {
            tempItemSaveList = this.ruleForm.inputVidList
          }
        });
        if (this.errorItem.length === 0) {
          // 手动输入时传 custom_id_list 批次信息只要1个
          const params = { custom_id_list: tempItemSaveList }
          const list = await this.getUsableTagList(params);
          if (list.length === 0) {
            this.$message.error(this.$t("datapoint.add_id_dialog_end_error_id"));
            return false;
          }
          // 每次修改都会替换原先数据
          this.UseVIDList = list;
        } else {
          this.$message.error(this.$t("common.components_message_delete"));
        }
      }

      const vidList = this.UseVIDList.map(item => item.vid);
      this.formLabelAlign.value = vidList; // 往上提交的数据中只需要vid列表
      this.dialogVisible = false;
      this.cleanInputVidList();
    },
  },
};
</script>

<style lang="scss" scoped>
.duc-label {
  font-size: 16px;
  color: #2c3e50;
	font-family: PingFang SC, 'Avenir', Helvetica, Arial, sans-serif;
}

::v-deep .el-table__empty-text {
  & > div {
    line-height: 20px !important;
  }
}
::v-deep .el-form-item__label {
  font-size: 16px !important;
  color: #2c3e50 !important;
  margin-bottom: 5px;
  margin-top: 10px;
}

.options-box {
  height: auto;
  max-height: 900px;
  overflow-y: auto;
  padding-bottom: 30px;
}

.options-btn {
  position: absolute;
  border-top: 1px solid #e4e7ed;
  font-size: 14px;
  text-align: center;
  color: #606266;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: #fff;
  cursor: pointer;
  z-index: 99;
}

.tabs-type-box {
  margin-top: -20px;
  margin-bottom: 10px;
  .el-col {
    &:last-child {
      .tabs-type-item {
        border-right: 1px solid #d8d9e0;
      }
    }

    .tabs-type-item {
      height: 40px;
      line-height: 40px;
      background: #ffffff;
      border: 1px solid #d8d9e0;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #000000;
      border-right: 0;
      cursor: pointer;
    }

    .tabs-type-item-active {
      background: #f2f5ff;
      border: 1px solid #1890ff !important;
      color: #1890ff;
    }
  }
}

.popover-class {
  position: absolute;
  right: 5px;
  top: 0;
  width: 12px;
  height: 12px;
  color: #999999;
  cursor: pointer;
}

.progress-bar {
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 20;
  width: 100%;
}

.components-relative {
  position: relative;
  width: 100%;
  font-size: 14px;
}

.avatar {
  max-width: 100%;
  max-height: 100%;
}

.innerTagIMG {
  position: absolute;
  left: 45px;
  bottom: 45px;
}

.el-form>>>.el-form-item__label {
  padding: 0;
}

.delDiv {
  position: absolute;
  z-index: 100;
  left: 147px;
  bottom: 116px;
  background-color: rgba(232, 240, 252, 0.8);
  border-radius: 0 5px 5px 0;
  border: 1px dashed #c0ccda;
}

.delDiv:hover {
  z-index: 100;
  cursor: pointer;
}

.top-set-upload-logo {
  position: absolute;
  width: 146px;
  height: 146px;
  z-index: 20;
  background-color: transparent;
  opacity: 0;
}

.top-set-upload-logo:hover {
  position: absolute;
  width: 146px;
  height: 146px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.components-box-style .el-form-item {
  width: 100%;
}

.components-relative .el-form-item {
  width: 100%;
}

.requires_must::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.order-item-vidlist-next-bottom {
  width: 100%;
  text-align: center;
  padding: 5px 0;
  cursor: pointer;
}

.order-item-vidlist-next-bottom:hover {
  background-color: #f1f1f1;
}

.pc-vidlist-component {
  margin: 20px 0;
}

.components-relative {
  position: relative;
}

.components-vidlist-buttom {
  position: absolute;
  text-align: right;
  font-size: 12px;
  color: #999999;
  right: 0;
  bottom: 0;
  padding: 2px 20px;
  background-color: #9b9b9b;
  opacity: 0.5;
  color: #ffffff;
  border-radius: 4px 0 4px 0;
  z-index: 10;
}

.icon-add-type-button {
  position: absolute;
  top: -15px;
  font-size: 30px;
  right: -15px;
  z-index: 2;
}

.icon-delete-all-button {
  position: absolute;
  top: -12px;
  font-size: 25px;
  right: 35px;
  z-index: 2;
}

.icon-delete-type-button {
  position: absolute;
  top: -8px;
  font-size: 10px;
  right: 49px;
}

.infinite-list {
  height: 200px;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  width: 100%;
}

.infinite-list-item {
  height: 40px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-item-vidlist {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
}

.order-item-infomation {
  height: 90px;
  flex: 1;
  margin-left: 20px;
}

.order-item-infomation div {
  width: 100%;
  display: flex;
  height: 45px;
  align-items: flex-start;
  justify-content: space-between;
}

.tap-top-unit-point {
  font-size: 12px;
  padding: 6px 0;
  border-radius: 3px 3px 0 0;
  color: $color-primary;
  background-color: #ffffff;
  border-bottom: 3px solid $color-primary;
  text-align: center;
  cursor: pointer;
}

.tap-top-unit-point:hover {
  color: #ffffff;
  background-color: $color-primary;
  border-bottom: 3px solid $color-primary;
}

.tap-top-unit-point-select {
  font-size: 12px;
  padding: 6px 0;
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  background-color: $color-primary;
  border-bottom: 3px solid $color-primary;
  text-align: center;
}

.footer-class {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cursor-not-allowed {
  color: #c0c4cc !important;
  cursor: not-allowed !important;
}

</style>

