// const lang = window.navigator.language.indexOf('zh') > -1 ? 'zh-CN' : 'en-US' // 浏览器语言
import { parseQueryStr } from '@/utils/common'
const { language } = parseQueryStr(window.location.search);

export default {
  localize(state: any,itemname: any, key: any, defaultVal: any) {
    if (state.languagePackage.length <= 0) {
      return defaultVal
    }

    var languagePack = state.languagePackage[0][language === 'en' ? 'en-US' : 'zh-CN']
    var base = (languagePack || {})['base']
    var custom = (languagePack || {})['custom']
    var local = (base && base[itemname]) || (custom && custom[itemname])
    if(!local) {
      return defaultVal
    }
    return local[key] || defaultVal
  }
}
