<template>
  <div>
    <div v-if="jsonContent" v-for="(item,index) in jsonContent" :key="item.type+index">
      <component
        v-if="registerTemplateName_SAVE.includes(`C${item.type.replace(/-/g, '')}`)"
        :is="`C${item.type.replace(/-/g, '')}`"
        :jsonContent="item.dataReference"
        :fatherContent="item"
        :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
        @fatherCall="setUpdateJSON"
        @dcpDetail="viewDcpDetail"
        @updateRefDcu="updateRefDcuData"
        @cacheDatamodel="cacheDatamodelData"
        :disabled="disabled"
        :jsonReferenceListContent="referenceList"
        :contextData="contextData"
        :editStatus="editStatus"
        ref="childComponents"
      ></component>
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      commonSave: this.commonSave
    }
  },
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled",
    "referenceList", "contextData", "editStatus", "invisibleKeyPathList", "disableKeyPathList"],
  data() {
    return {
      // jsonContent: [],
      callBackJson: {},
      jsonContext: {},
      refData:"",
      commonSave: {
        invisibleKeyPathList: this.invisibleKeyPathList,
        disableKeyPathList: this.disableKeyPathList || []
      }
    };
  },
  computed: { },
  watch: {},
  methods: {
    setUpdateJSON(data) {
      this.$forceUpdate()
      this.callBackJson[data.key] = data.value;
      this.$emit("fatherCall", {
        value: this.callBackJson
      });
    },
    cacheDatamodelData(){
      this.$emit("cacheDatamodel")
    },
    updateRefDcuData(data){
      this.refData = data
      this.$emit("updateRefDcu", data)
    },
    viewDcpDetail(dcp){
      this.$emit("dcpDetail", dcp)
    }
  },
  created() {
    this.$store.state.vidlistComponents = []
    // this.jsonContent = track.attributes
  },
  mounted() {}
};
</script>
<style lang='scss' scoped>
.form-item {
  margin-bottom: 10px;
}
.item-label {
  text-align: right;
  padding-top: 10px;
  word-break: break-word;
}
.item-content {
  margin-bottom: 5px;
}
</style>
