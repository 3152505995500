export const shortVid = (vid: string) => {
  if (!vid || typeof vid !== "string") return vid
  let originVid = vid
  // if(vid.indexOf(',') > 0){
  //   originVid = vid.split(',')[1]
  // }
  if(originVid.length <= 12){
    return originVid
  }
  return originVid.replace(originVid.substring(6, originVid.length - 6), "......");
}

export const splitVid = (vid: string) =>  {
  if (!vid) return ''
  if(vid && vid.indexOf(',') > -1) {
    return vid.split(',')[1]
  }
  return vid.replace(vid.substring(6, vid.length - 6), "......");
}

export const createRequestId = () => {
  return (
    +new Date() +
    Math.random()
      .toString()
      .substr(2, 4)
  );
}
