import { commonStoreModule } from '@/store/module/common'
import { storage, checkNull } from './index'

const mixin = {
  computed: {},
  data() {},
  methods: {
    getComponents(uuid: string): string {
      return ""
    },
    getLang() {
      return storage(commonStoreModule.langLocale).get()
    },
    getToken(): void {
      console.log()
    },
    gridStyle(obj1: any): object {
      return Object.assign(obj1.layer, obj1.style) || {}
    },
    checkNull (msg: any) {
      return checkNull(msg)
    },
  }
}
export default (tmp: any) => {
  Object.assign(mixin, {
    data() {
      return {
        ...tmp
      }
    }
  })
  return mixin
}
